import React from 'react'
import  { useNavigate } from 'react-router'
import { useState,useEffect } from 'react';
import { Amplify, Auth, API,Cache } from 'aws-amplify';
// import {Flex, View, Divider,Link,Alert} from '@aws-amplify/ui-react';
import  NavBar  from '../components/navbar.tsx';
import studioTheme from '../ui-components/studioTheme';
import SideBar from '../ui-components/SideBar';
import AccountAccountInformation from '../ui-components/AccountAccountInformation'; 
import { Flex, Text, View, TextField, CheckboxField, Button, Image, withAuthenticator} from "@aws-amplify/ui-react";
import Modal from 'react-modal';
import awsconfig from './../aws-exports';
import { getUser,getStripeCustomer } from '../graphql/queries';
import { createImportSpecifier } from 'typescript';
import Selectedbutton from '../ui-components/SelectedButton';
import Partners from '../ui-components/Partners';
const { DateTime } = require("luxon");


Amplify.configure(awsconfig);

const LoginPage = ({ setUser }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const [forgotPasswordCode, setForgotPasswordCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [forgotPasswordError, setForgotPasswordError] = useState('');
  const [forgotPasswordStep, setForgotPasswordStep] = useState('request'); 

  const navigate = useNavigate();

  const handleSignIn = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError('');
    try {
      const user = await Auth.signIn(username, password);
      setUser(user);
      let cognitoUser = await Auth.currentAuthenticatedUser();
      const { attributes } = cognitoUser;
      const result = await API.graphql({ query: getUser , variables:{id: username}});
      if(result['data'] && result['data'].getUser){
        var response;
        
        response = await API.graphql({
            query: getStripeCustomer
            ,variables : {"email": username, "stripeCustomerId": result['data'].getUser["stripeId"]}
        })
        const stripeData = JSON.parse(response["data"]["getStripeCustomer"]);

        var currentPlan = null;
        var currentCustomer = null;
        //find the exact plan they are on currently
        if(stripeData['body'] && stripeData['body']['data']){
          for(var i = 0; i < stripeData['body']['data'].length;i++){
            currentCustomer = stripeData['body']['data'][i];
            if(currentCustomer && currentCustomer['customer'] == result['data'].getUser["stripeId"]){
              //found the current plan 
              currentPlan = stripeData['body']['data'][i]['plan'];
            }
          }
        }

       
        if(currentPlan == null){
          let product = currentPlan['product']
          result['data'].getUser['plan'] = product;

          await new Promise((resolve) => {
            Cache.setItem('user', result['data'].getUser);
            resolve(); // Resolves immediately after setting the item
          });

          navigate("/products");
        }
        else {
          let product = currentPlan['product']
          result['data'].getUser['plan'] = product;

          await new Promise((resolve) => {
            Cache.setItem('user', result['data'].getUser);
            resolve(); // Resolves immediately after setting the item
          });
        
          const subscriber = currentPlan["active"];
          
          if (!subscriber) {
            navigate("/products");
          } else {
            navigate("/sales-report");
          }
        }
       
      }
     
    } catch (err) {
      setError('Failed to sign in. Please check your username and password.');
      setIsLoading(false);
    }
  };

  const formStyles = {
    all: 'unset',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '100%',
    alignItems: 'center',
  };

  const childElementStyles = {
    all: 'unset',
  };

  const redirectToSignup =() =>{
    navigate('/signup');
  }



  const handleRequestForgotPassword = async (event) => {
    event.preventDefault();
    setForgotPasswordError('');
    try {
      await Auth.forgotPassword(forgotPasswordEmail);
      setForgotPasswordStep('confirm');
    } catch (err) {
      setForgotPasswordError(`Failed to send reset code. ${err.message}`);
    }
  };

  const handleConfirmForgotPassword = async (event) => {
    event.preventDefault();
    setForgotPasswordError('');
    try {
      await Auth.forgotPasswordSubmit(forgotPasswordEmail, forgotPasswordCode, newPassword);
      alert('Password successfully reset!');
      setForgotPasswordStep('request'); // Go back to the previous step
      setForgotPasswordEmail(''); // Optionally, clear the email field
      setShowForgotPasswordModal(false);
    } catch (err) {
      setForgotPasswordError(`Failed to reset password. ${err.message}`);
    }
  };

  
  return (
    <Flex
      direction={{ base: "column", medium: "row" }}
      width="100vw"
      height="100vh"
    >
    <Flex
      direction="column"
      width={{ base: "100%", medium: "45%" }}
      justifyContent="center"
      alignItems="center"
      padding={{ base: "20px", medium: "40px" }}
      style={{ backgroundColor: 'white', }}
    >
    <Flex
          direction="column"
          width="100%"
          height="70%"
          boxSizing="border-box"
          justifyContent="center"
          alignItems="center"
        >
    <Image
      src="aitium.png"
      alt="Aitium logo"
      style={{ width: '100px', height: '100px', }}
    />
    <Text
      style={{
        fontFamily: 'Inter',
        fontSize: '1.5rem',
        fontWeight: '500',
        color: 'rgba(13,26,38,1)',
        // marginBottom: '10px',
      }}
    >
      Welcome back!
    </Text>
    <form onSubmit={handleSignIn} style={formStyles}>

    {error && <Text color="red">{error}</Text>}
        <Flex
          direction="row"
          width="90%"
          justifyContent="center"
          alignItems="center"
        >
        
        
          <TextField
            label="Email"
            placeholder=""
            width="100%"
            height="80%"
            type="email"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </Flex>
        <Flex
          direction="row"
          width="90%"
          justifyContent="center"
          alignItems="center"
        >
          <TextField
            label="Password"
            placeholder=""
            width="100%"
            height="80%"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </Flex>
        
        <Flex
          direction="row"
          width="90%"
          justifyContent="space-between"
          alignItems="center"
          style={{ marginBottom: '5px' }}
        >
          <CheckboxField
            label="Remember me"
            defaultChecked={true}
          />
          <Button
            variation="link"
            onClick={() => setShowForgotPasswordModal(true)}
            style={{ fontSize: '14px', justifyContent: 'center', alignItems: 'center' }}
          >
            Forgot Password
          </Button>
        </Flex>
        <Flex
          direction="row"
          width="90%"
          justifyContent="space-between"
          alignItems="center"
          style={{  }}
        >
          <Button
            type="submit"
            isLoading={isLoading}
            style={{ width: '100%', color: 'white', backgroundColor: '#00607A' }}
          >
          {isLoading ? 'Signing In...' : 'Sign In'}
          </Button>
    
        </Flex>
    </form>
    <View
      // display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      style={{ height: '60px' }} // Adjust height and padding
    >
      <Text style={{ fontSize: '14px' }}>Don't have an account?</Text>
      <Button variation="link" onClick={redirectToSignup} style={{ fontSize: '14px',  justifyContent: "center",
        alignItems: "center" }}>Sign up</Button>
    </View>

    </Flex>
  </Flex>
      <Flex
        direction="column"
        width={{ base: "100%", medium: "55%" }}
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        padding="20px"
        style={{ backgroundColor: '#EFF0F0', textAlign: 'center' }}
      >
      <Image
          src="gaiff_logo.png"
          alt="GAIFF logo"
          style={{ width: '50%',}}
        />
        <Flex
          direction="row"
          marginTop="10px"
        >
          <Text
          textAlign="center"
            style={{
              fontFamily: 'Inter',
              fontSize: '2.0rem',
              fontWeight: '400',
              color: 'rgba(13,26,38,1)',
              marginBottom: '20px',
            }}
          >
          Aitium: Advancing your Amazon Business
          </Text>
        </Flex>
      </Flex>

      {/* Forgot Password Modal */}
      <Modal
        isOpen={showForgotPasswordModal}
        onRequestClose={() => setShowForgotPasswordModal(false)}
        style={{ 
          content: { 
            width: '80%', 
            maxWidth: '500px', 
            margin: 'auto',
            padding: '20px',
            boxSizing: 'border-box' ,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            maxHeight: '65%',
            overflowY: 'auto',
          },
          overlay: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }
        }}
      >
        <h2>Forgot Password</h2>
        <form onSubmit={forgotPasswordStep === 'request' ? handleRequestForgotPassword : handleConfirmForgotPassword}>
          {forgotPasswordStep === 'request' ? (
            <>
              <TextField
                label="Email"
                type="email"
                value={forgotPasswordEmail}
                onChange={(e) => setForgotPasswordEmail(e.target.value)}
                required
                style={{ marginBottom: '40px' }} 
              />
              <Button
                type="submit"
                isLoading={isLoading}
                style={{ 
                  width: '100%', 
                  color: 'white', 
                  backgroundColor: '#00607A', 
                  marginBottom: '15px' 
                }}
              >
                {isLoading ? 'Sending Code...' : 'Send Code'}
              </Button>
              {forgotPasswordError && <Text color="red" style={{ marginBottom: '15px' }}>{forgotPasswordError}</Text>}
              <Button 
                onClick={() => setShowForgotPasswordModal(false)} 
                style={{ width: '100%' }}
              >
                Cancel
              </Button>
            </>
          ) : (
            <>
              <TextField
                label="Confirmation Code"
                value={forgotPasswordCode}
                onChange={(e) => setForgotPasswordCode(e.target.value)}
                required
                style={{ marginBottom: '15px' }}
              />
              <TextField
                label="New Password"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                style={{ marginBottom: '40px' }}
              />
              <Button
                type="submit"
                isLoading={isLoading}
                style={{ 
                  width: '100%', 
                  color: 'white', 
                  backgroundColor: '#00607A', 
                  marginBottom: '15px' 
                }}
              >
                {isLoading ? 'Resetting Password...' : 'Reset Password'}
              </Button>
              {forgotPasswordError && <Text color="red" style={{ marginBottom: '15px' }}>{forgotPasswordError}</Text>}
              <Button 
                onClick={() => setShowForgotPasswordModal(false)} 
                style={{ width: '100%' }}
              >
                Cancel
              </Button>
            </>
          )}
        </form>
      </Modal>
    </Flex>
  );
}

export default LoginPage;



